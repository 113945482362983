import React, { useState, Fragment } from "react";
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { graphql } from "gatsby"
import Header from "../components/Header/Header"
import PropertyBar from "../components/PropertyHeader/PropertyHeader"
import PropertyBanner from "../components/PropertyBanner/PropertyBanner"
import PropertyInfo from "../components/PropertyInfo/PropertyInfo"
import PropertyDetails from "../components/PropertyDescription/PropertyDescription"
import ContactCard from "../components/ContactSidebar/PropertySidebar"
import Facility from "../components/Facilities/Facilities"
import SimilarProperty from "../components/SimilarProperty/Property"
import Footer from "../components/Footer/Footer"
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss"
import "../scss/grunticon.scss"
import "../pages/PropertyDetails/PropertyDetails.scss"
import PropertyMap from "../components/PropertyMap/PropertyMap"
import AboutProperty from "../components/AboutProperty/AboutProperty"
import Btn from "../components/BottomBtn/Btn"
import { useWindowSize } from "../hooks/window-size"
import MortgageRepayments from "../components/MortgageRepayments/MortgageRepayments"
import RentalYield from "../components/MortgageRepayments/RentalYield"
import { AnimationOnScroll } from "react-animation-on-scroll"
import BrandLogo from "../images/mccone-logo.png"
import Help from "../components/Help/Help"
import SEO from "../components/Seo/seo";
import Sticky from "react-stickynode"
import { toTitleCase } from "../components/property-search/utils"
import Property404 from "../pages/Property404"
import { Helmet } from "react-helmet"

import {
  UserObjectStoreProvider,
  useAuthState,
  getUserobjectData,
  savedSearchParams,
  SaveItem,
} from "@starberry/myaccount-website-utils"

const STAGE_ENV = process.env.GATSBY_STRAPI_GGFX_ENV || "i.dev"

const PROPERTY_DETAILS = gql`
  query GetProperty($id: String!) {
    properties(where: { id: $id, publish:true}) {
      id
      crm_id
      status
      bedroom
      bathroom
      building
      community
      address
      amenities
      size
      title
      price
      price_on_request
      price_qualifier
      listing_id
      virtual_tour
      department
      search_type
      negotiator_details
      price_qualifier
      display_address
      sub_community
      description
      long_description
      latitude
      longitude
      images
      imagetransforms
      ghost_property
    }
  }
`

// markup
const PropertyInfoComponent = props => {
  const [windowWidth] = useWindowSize()
  const [prseometachk, setPrseometachk] = React.useState(false)

  const HeaderCard = [
    {
      title: "Mortgage Repayments",
      Content: "Calculate your monthly repayments with our mortgage calculator",
    },
  ]
  const capitalize = s => {
    if (typeof s !== "string") return ""
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  //
  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  let myid_filt = pageurl.split("?")
  let mypageurl = myid_filt[0]
  let property_slug = mypageurl.split("-")
  let id = property_slug[property_slug.length - 1]
  id = id.replace("/", "")

  const {
    loading,
    error,
    data: property_details,
  } = useQuery(PROPERTY_DETAILS, {
    variables: { id: id },
  })

  let whatsAppMessage = ""
  var metatitle = ""
  var metadesc = ""
  var seo_image_url = ""
  if (property_details && property_details.properties.length > 0 && typeof window !== "undefined") {
    const urlSections = window.location.href.split("/")

    let propertyData = property_details.properties[0]

    whatsAppMessage = `Hello, I would like to get more information about this property you posted on ${urlSections[2]}: Reference: ${propertyData.listing_id} Type: ${propertyData.building} Price: ${propertyData.price} AED/${propertyData.price_qualifier} Location: ${propertyData.community} Link: ${pageurl}`
  }

  // React.useEffect(() => {

  // }, [prseometachk])

  // Loading logo
  if (loading)
    return (
      <section className={"loader-wrapper"}>
        <div id="loader-wrapper">
          <div id="loader" class="new-loader">
            <img
              className="logo-white loader-logo"
              src={BrandLogo} 
              alt="logo"
            />
          </div>
        </div>
      </section>
    )
  // Loading logo

  //console.log("property_detailsproperty_details", property_details && property_details.properties.length > 0 &&  property_details,id)
  const buildingType = ['land' , 'whole-building']
  return (
    <>
      <Header additionalClass="white" />
      {property_details &&
      property_details.properties &&
      property_details.properties.length > 0 && property_details.properties[0]?.ghost_property
        ? property_details.properties.map((data, i) => { 
            return (
              <> 
                <Helmet>
                  <meta name="robots" content="noindex" />
                </Helmet> 
                <div className={`mar-top`}></div>
                <PropertyBanner propertyData={data} isGhostPage={true} />
                <div className={`details-container ${data.search_type === "sales" ? "sale-details" : "rent-details"}`}>
                  <Container>
                    <Row>
                      <Col md={12} lg={8} xl={8}>
                        <PropertyInfo
                          propertyData={data}
                          userObjects={props?.userObjects}
                          isGhostPage={true} 
                        />
                        <PropertyDetails propertyData={data} />
                        {data?.amenities && (
                          <Facility amenities={data.amenities} />
                        )} 
                        {data.latitude && data.longitude && (
                          <PropertyMap
                            address={data.address}
                            lat={data.latitude}
                            lng={data.longitude}
                          />
                        )}
                        <div className="stop-sticky"></div>
                        <AboutProperty community={data.community} />
                        
                      </Col>
                      <Col lg={4} xl={4}>
                        <Sticky top=".header" bottomBoundary=".stop-sticky">
                          <ContactCard
                            id={data?.id}
                            neg_email={data?.negotiator_details?.email}
                            whatsAppMessage={whatsAppMessage}
                            isGhostPage={true} 
                          />
                        </Sticky>
                      </Col>
                    </Row>
                  </Container>
                </div> 
                <Help module="Lets_Work_Together_Module" />
              </>
            )
          })
        : <Property404/> }
      <div className="details-footer">
        <Footer />
        {property_details.properties[0]?.ghost_property &&
         <Btn id={id ? id : ""} whatsAppMessage={whatsAppMessage} isGhostPage={true} />
        }
      </div>
    </>
  )
}

const Property = props => {
  const [userObjects, setUserObjects] = React.useState({})
  const { state: authState, services: authServices } = useAuthState()
  const isAuthenticated = authServices.isAuthenticated()

  React.useEffect(() => {
    if (isAuthenticated) {
      const getUserObjects = async () => {
        try {
          const userObjects = await getUserobjectData({
            type: "property",
            state: true,
          })
          setUserObjects(userObjects.data.data)
        } catch (e) {
          console.log("e:", e)
        }
      }
      getUserObjects()
    }
  }, [])

  return (
    <UserObjectStoreProvider>
      <PropertyInfoComponent {...props} userObjects={userObjects} />
    </UserObjectStoreProvider>
  )
}

export default Property
