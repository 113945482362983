import React from "react";
import { Link } from "gatsby";
import contactImg from "../../images/contact.png";
import { TeamDetail } from "../../queries/common_use_query";
import "./ContactSidebar.scss";
import GetGGFXImage from "../common/site/get-ggfx-image";
import { navigate } from '@reach/router';



const ContactSidebar = (props) => {
  const { loading: loading, error: error, data: data } = TeamDetail(props.neg_email);
  var teamdata = data?.teams.length > 0 ? data.teams[0] : ""


  const bookViewing = (id) => {
    navigate('/book-a-viewing/', { state: { propertyId: id } });
  }

  if (props.isGhostPage) {
    return (
      <div className="contact-sidebar">
        <div className="dealer-cta d-none d-lg-flex">
          <Link to={`/contact/`} className="btn" >Get in Touch</Link>
        </div>

      </div>
    )

  } else if (teamdata) {
    let processedImages = JSON.stringify({});
    if (teamdata?.imagetransforms?.Image_Transforms) {
      processedImages = teamdata.imagetransforms.Image_Transforms;
    }
    return (
      <div className="contact-sidebar">
        <div className="dealer-cta d-none d-lg-flex">
          {props.isGhostPage &&
            <Link to={`/contact/`} className="btn" >Get in Touch</Link>
          }
          <a href={`tel:${teamdata.Phone}`} className="btn">
            <i className="icon-contact"></i>{teamdata.Phone}
          </a>
          <button onClick={() => { bookViewing(props.id) }} className="btn" >
            <i className="icon-mail"></i>
            Book a Viewing

          </button>
          <a href={`https://wa.me/+971585138750?text=${props.whatsAppMessage}`} target="_blank" class="btn whatsapp">
            <i className="icon-whatsapp"></i>Click to WhatsApp
          </a>
        </div>
        <div className="dealer-info">
          <div className="dealer-wrap">
            <div className="img-wrap zoom">
              {/* <img src={teamdata?.Image?.url} alt={teamdata.Name +" "+ teamdata.Designation}  /> */}
              <Link to={`/meet-the-team/${teamdata.URL}/`}>
                <GetGGFXImage imagename={"teams.Image.smallimg"} imagesources={teamdata.Image} fallbackalt={teamdata.Name + " " + teamdata.Designation} imagetransformresult={processedImages} id={teamdata.id} />

              </Link>
            </div>
            <div className="dealer-right">
              <Link to={`/meet-the-team/${teamdata.URL}/`}>
                <span className="dealer-name">{teamdata.Name}</span>
              </Link>

              <span className="dealer-position">{teamdata.Designation}</span>
              <span className="dealer-contact">
                <span className="title">Languages:</span>{teamdata.languages}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="contact-sidebar">
        <div className="dealer-cta d-none d-lg-flex">
          <button onClick={() => { bookViewing(props.id) }} className="btn" >
            <i className="icon-mail"></i>
            Book a Viewing

          </button>
          <a href={`https://wa.me/+971585138750?text=${props.whatsAppMessage}`} target="_blank" class="btn whatsapp">
            <i className="icon-whatsapp"></i>Click to WhatsApp
          </a>
        </div>

      </div>
    )
  }
};

export default ContactSidebar;
